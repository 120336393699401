import { Debqsurveyrequest, Usersusercreatev2modelrequest } from 'shared/api/forerunner'
import { EatingProfile } from 'shared/constants/eatingProfile'
import { State } from 'shared/providers/ClientDataProvider'
import { FrequencyOptions } from 'shared/providers/ClientDataProvider/constants/FrequencyOptions'
import { Gender } from 'shared/types/user'
import { convertHeight, convertWeight } from 'shared/utils/unit-converters'

interface UserProperties
  extends Omit<Usersusercreatev2modelrequest, 'email' | 'branch_name' | 'timezone'> {
  eating_profile_type: EatingProfile
}

type NonNullableProperties<T> = {
  [K in keyof T]: NonNullable<T[K]>
}

export const clientToBackendProperties = (clientData: State, eatingProfile: EatingProfile) => {
  const {
    height,
    currentWeight,
    targetWeight,
    age,
    unitSystem,
    behaviorGuilt,
    behaviorPush,
    behaviorSocial,
    behaviorStPlate,
    behaviorStSocialize,
    behaviorTrigger,
    gender,
    healthGoalReason,
    nutritionEatingHabits,
    nutritionFoodCravings,
    nutritionHowToFitInMyLife,
    nutritionHungryTimeOfDay,
    nutritionPreferredMeal,
    nutritionStressEating,
    occasionDate,
    weightLossFocus,
    weightLossMotivations,
    weightLossPace,
    adhdStatement1,
    adhdStatement2,
    adhdStatement3,
    adhdStatement4,
    adhdStatement5,
    adhdStatement6,
    adhdStatement7,
    adhdStatement8,
    // debq quiz properties
    debqEmotionalEatDuringUnpleasantAnticipation,
    debqEmotionalEatWhenBored,
    debqEmotionalEatWhenChilling,
    debqEmotionalEatWhenDepressed,
    debqEmotionalEatWhenDisappointed,
    debqEmotionalEatWhenEmotionalUpset,
    debqEmotionalEatWhenFailed,
    debqEmotionalEatWhenIrritated,
    debqEmotionalEatWhenLetDown,
    debqEmotionalEatWhenLonely,
    debqEmotionalEatWhenScared,
    debqEmotionalEatWhenUpset,
    debqEmotionalEatWhenWorried,
    debqExternalBuyFoodWhenPassingBakery,
    debqExternalBuyFoodWhenPassingCafe,
    debqExternalEatDeliciousFoodInstantly,
    debqExternalEatIfGoodLookingFood,
    debqExternalEatMoreWhenGoodLookingFood,
    debqExternalEatMoreWhenOthersEating,
    debqExternalEatTastyFood,
    debqExternalEatWhenSeeEatingPeople,
    debqExternalHardToResistTastyFood,
    debqExternalSnackingWhileCooking,
    debqRestrainedConsiderWeightWhenChooseFood,
    debqRestrainedEatLess,
    debqRestrainedEatLessAfterOvereating,
    debqRestrainedEatLessDuringMeals,
    debqRestrainedEatLessWhenGainWeight,
    debqRestrainedEatingMonitoring,
    debqRestrainedFoodForWl,
    debqRestrainedNoEatBetweenMeals,
    debqRestrainedNoEveningEating,
    debqRestrainedRefuseFood,
    fitnessLevel,
    currentBodyType,
    targetBodyType,
    lastHappyWeight,
    childhoodTrauma,
    motivationChange,
    sleep,
    stressImage,
    previousAttempts,
    previousMentalHealth,
    knowBehavioral,
    baseStatement1,
    baseStatement2,
    baseStatement3,
    em1,
    em2,
    em3,
    em4,
    lifeEvents,
  } = clientData as NonNullableProperties<typeof clientData>

  const metricHeight =
    height.value &&
    convertHeight({
      value: height.value,
      fromUnit: height.unit || 'cm',
      toUnit: 'cm',
      places: 2,
    })

  const metricWeight =
    currentWeight.value &&
    convertWeight({
      value: currentWeight.value,
      fromUnit: currentWeight.unit || 'kg',
      toUnit: 'kg',
      places: 2,
    })

  const metricTargetWeight =
    targetWeight.value &&
    convertWeight({
      value: targetWeight.value,
      fromUnit: targetWeight.unit || 'kg',
      toUnit: 'kg',
      places: 2,
    })

  const isUserChoseUnits = height.value || currentWeight.value || targetWeight.value

  const hasDEBQAnswers = checkIfHasDEBQSurveyAnswers(
    debqEmotionalEatDuringUnpleasantAnticipation,
    debqEmotionalEatWhenBored,
    debqEmotionalEatWhenChilling,
    debqEmotionalEatWhenDepressed,
    debqEmotionalEatWhenDisappointed,
    debqEmotionalEatWhenEmotionalUpset,
    debqEmotionalEatWhenFailed,
    debqEmotionalEatWhenIrritated,
    debqEmotionalEatWhenLetDown,
    debqEmotionalEatWhenLonely,
    debqEmotionalEatWhenScared,
    debqEmotionalEatWhenUpset,
    debqEmotionalEatWhenWorried,
    debqExternalBuyFoodWhenPassingBakery,
    debqExternalBuyFoodWhenPassingCafe,
    debqExternalEatDeliciousFoodInstantly,
    debqExternalEatIfGoodLookingFood,
    debqExternalEatMoreWhenGoodLookingFood,
    debqExternalEatMoreWhenOthersEating,
    debqExternalEatTastyFood,
    debqExternalEatWhenSeeEatingPeople,
    debqExternalHardToResistTastyFood,
    debqExternalSnackingWhileCooking,
    debqRestrainedConsiderWeightWhenChooseFood,
    debqRestrainedEatLess,
    debqRestrainedEatLessAfterOvereating,
    debqRestrainedEatLessDuringMeals,
    debqRestrainedEatLessWhenGainWeight,
    debqRestrainedEatingMonitoring,
    debqRestrainedFoodForWl,
    debqRestrainedNoEatBetweenMeals,
    debqRestrainedNoEveningEating,
    debqRestrainedRefuseFood
  )

  const preparedParams: UserProperties = {
    height: metricHeight ? metricHeight.toString(10) : undefined,
    weight: metricWeight ? metricWeight.toString(10) : undefined,
    target_weight: metricTargetWeight ? metricTargetWeight.toString(10) : undefined,
    age: Number(age) || undefined,
    units: isUserChoseUnits ? unitSystem : undefined,
    gender: (gender as Gender) || undefined,
    behavior_guilt: behaviorGuilt,
    behavior_push: behaviorPush,
    behavior_social: behaviorSocial,
    behavior_st_plate: behaviorStPlate,
    behavior_st_socialize: behaviorStSocialize,
    behavior_trigger: behaviorTrigger,
    eating_profile_type: eatingProfile,
    health_goal_reason: healthGoalReason,
    nutrition_eating_habits: nutritionEatingHabits,
    nutrition_food_cravings: nutritionFoodCravings,
    nutrition_how_to_fit_in_my_life: nutritionHowToFitInMyLife,
    nutrition_hungry_time_of_day: nutritionHungryTimeOfDay,
    nutrition_preferred_meal: nutritionPreferredMeal,
    nutrition_stress_eating: nutritionStressEating,
    occasion_date: occasionDate?.toISOString(),
    weight_loss_focus: weightLossFocus,
    weight_loss_motivations: weightLossMotivations,
    weight_loss_pace: weightLossPace,
    fitness_level: fitnessLevel,
    current_body_type: currentBodyType,
    target_body_type: targetBodyType,
    last_happy_weight: lastHappyWeight,
    childhood_trauma: childhoodTrauma,
    motivation_change: motivationChange,
    adhd_statement_1: adhdStatement1,
    adhd_statement_2: adhdStatement2,
    adhd_statement_3: adhdStatement3,
    adhd_statement_4: adhdStatement4,
    adhd_statement_5: adhdStatement5,
    adhd_statement_6: adhdStatement6,
    adhd_statement_7: adhdStatement7,
    adhd_statement_8: adhdStatement8,
    sleep,
    stress_image: stressImage,
    previous_attempts: previousAttempts,
    previous_mental_health: previousMentalHealth,
    know_behavioral: knowBehavioral,
    base_statement_1: baseStatement1,
    base_statement_2: baseStatement2,
    base_statement_3: baseStatement3,
    life_events: lifeEvents,
    em1,
    em2,
    em3,
    em4,
  }

  if (hasDEBQAnswers) {
    preparedParams['debq_survey'] = {
      emotional_eat_during_unpleasant_anticipation: Number(
        debqEmotionalEatDuringUnpleasantAnticipation
      ),
      emotional_eat_when_bored: Number(debqEmotionalEatWhenBored),
      emotional_eat_when_chilling: Number(debqEmotionalEatWhenChilling),
      emotional_eat_when_depressed: Number(debqEmotionalEatWhenDepressed),
      emotional_eat_when_disappointed: Number(debqEmotionalEatWhenDisappointed),
      emotional_eat_when_emotional_upset: Number(debqEmotionalEatWhenEmotionalUpset),
      emotional_eat_when_failed: Number(debqEmotionalEatWhenFailed),
      emotional_eat_when_irritated: Number(debqEmotionalEatWhenIrritated),
      emotional_eat_when_let_down: Number(debqEmotionalEatWhenLetDown),
      emotional_eat_when_lonely: Number(debqEmotionalEatWhenLonely),
      emotional_eat_when_scared: Number(debqEmotionalEatWhenScared),
      emotional_eat_when_upset: Number(debqEmotionalEatWhenUpset),
      emotional_eat_when_worried: Number(debqEmotionalEatWhenWorried),
      external_buy_food_when_passing_bakery: Number(debqExternalBuyFoodWhenPassingBakery),
      external_buy_food_when_passing_cafe: Number(debqExternalBuyFoodWhenPassingCafe),
      external_eat_delicious_food_instantly: Number(debqExternalEatDeliciousFoodInstantly),
      external_eat_if_good_looking_food: Number(debqExternalEatIfGoodLookingFood),
      external_eat_more_when_good_looking_food: Number(debqExternalEatMoreWhenGoodLookingFood),
      external_eat_more_when_others_eating: Number(debqExternalEatMoreWhenOthersEating),
      external_eat_tasty_food: Number(debqExternalEatTastyFood),
      external_eat_when_see_eating_people: Number(debqExternalEatWhenSeeEatingPeople),
      external_hard_to_resist_tasty_food: Number(debqExternalHardToResistTastyFood),
      external_snacking_while_cooking: Number(debqExternalSnackingWhileCooking),
      restrained_consider_weight_when_choose_food: Number(
        debqRestrainedConsiderWeightWhenChooseFood
      ),
      restrained_eat_less: Number(debqRestrainedEatLess),
      restrained_eat_less_after_overeating: Number(debqRestrainedEatLessAfterOvereating),
      restrained_eat_less_during_meals: Number(debqRestrainedEatLessDuringMeals),
      restrained_eat_less_when_gain_weight: Number(debqRestrainedEatLessWhenGainWeight),
      restrained_eating_monitoring: Number(debqRestrainedEatingMonitoring),
      restrained_food_for_wl: Number(debqRestrainedFoodForWl),
      restrained_no_eat_between_meals: Number(debqRestrainedNoEatBetweenMeals),
      restrained_no_evening_eating: Number(debqRestrainedNoEveningEating),
      restrained_refuse_food: Number(debqRestrainedRefuseFood),
    } as Debqsurveyrequest
  }

  return preparedParams
}

const checkIfHasDEBQSurveyAnswers = (...answerFields: Array<undefined | FrequencyOptions>) =>
  answerFields.every((field) => field !== undefined)
