import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { useMemo, useEffect } from 'react'

import { withCoreProviders } from 'shared/components/App/hocs'
import { AppOnboarding } from 'shared/components/AppOnboarding'
import { SplashScreen } from 'shared/components/SplashScreen'

import { useDocumentSocialWebViewBrowser } from 'shared/hooks/useDocumentOperations'

import { BLOG_ROUTE_RGX, PUBLIC_ROUTE_RGX } from 'shared/constants/navigation'

import { useLogger } from './hooks'

import { PageTitle } from '../PageTitle'

const AppResetPassword = dynamic(
  () => import('shared/components/AppResetPassword').then((mod) => mod.AppResetPassword),
  {
    ssr: false,
  }
)

const AppBlog = dynamic(() => import('shared/components/AppBlog').then((mod) => mod.AppBlog))

const LocalizedYup = dynamic(
  () => import('shared/components/LocalizedYup').then((mod) => mod.LocalizedYup),
  {
    ssr: false,
  }
)

const getAppComponent = (asPath: string) => {
  if (BLOG_ROUTE_RGX.test(asPath)) {
    return AppBlog
  }

  if (PUBLIC_ROUTE_RGX.test(asPath)) {
    return AppResetPassword
  }

  return AppOnboarding
}

const MyAppComponent = (props: AppProps) => {
  useLogger()
  useDocumentSocialWebViewBrowser()

  useEffect(() => {
    window.APP_BUILD_DATE = process.env.APP_BUILD_DATE || ''
    window.APP_VERSION = process.env.APP_VERSION || ''
  }, [])

  const { asPath } = useRouter()

  const AppComponent = useMemo(() => getAppComponent(asPath), [asPath])

  return (
    <>
      <PageTitle />

      <AppComponent {...props} />

      <ReactQueryDevtools initialIsOpen={false} />

      <LocalizedYup />

      <SplashScreen />
    </>
  )
}

const MyApp = withCoreProviders(MyAppComponent)

export { MyApp }
