import Router, { useRouter } from 'next/router'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { BaseScreen, PaymentScreen } from 'shared/api/testania'
import { startScreensIds } from 'shared/config/startScreens'
import { ScreenId } from 'shared/config/types'
import { useAnalytics } from 'shared/providers/AnalyticsProvider'
import { useAuthUser } from 'shared/providers/AuthUserProvider'
import { State } from 'shared/providers/ClientDataProvider'
import { useFlowLoader } from 'shared/providers/FlowLoader/FlowLoader'
import { useLocalization } from 'shared/providers/LocalizationProvider'
import { useOneTrust } from 'shared/providers/OneTrustProvider'
import { logger } from 'shared/utils/logger'

import { useInitialNavigation } from './hooks/useInitialNavigation'
import { useOpenNextPage } from './hooks/useOpenNextPage'
import { useSkipToPaymentPage } from './hooks/useSkipToPaymentPage'

import { getStartPageFromExternalSource } from './helpers'
import { FlowManager } from './helpers/FlowManager'

interface FlowManagerContextInterface {
  models: {
    currentScreen: BaseScreen | PaymentScreen
    /**
     * Sequence of screens in onboarding flow with {screen}.config.step = true
     */
    onboardingSteps: BaseScreen[]
    /**
     * Sequence of screens in onboarding flow
     */
    onboardingFlow: BaseScreen[]
    paymentFlow: PaymentScreen[]
    isPaymentFlowInProgress: boolean
    isStartPageActive: boolean
  }
  operations: {
    filterOnboardingFlowByState: (state: State) => void

    registerCallbackBeforeOpenNextPage: (callback: () => void) => void

    openNextPage: (options?: {
      isPaidOverride?: boolean
      nextScreenId?: ScreenId
      delay?: number
    }) => void

    skipToPaymentPage: (delay?: number) => void
  }
}

export const FlowManagerContext = createContext<FlowManagerContextInterface | null>(null)

export const useRawFlowManager = () => useContext(FlowManagerContext)

export function useFlowManager() {
  const context = useRawFlowManager()

  if (!context) {
    throw new Error(`useFlowManager must be used within FlowManagerProvider`)
  }

  return context
}

interface FlowManagerProviderProps {
  children?: React.ReactNode
  currentScreenId: ScreenId
}

export function FlowManagerProvider({
  children,
  currentScreenId,
}: Readonly<FlowManagerProviderProps>) {
  const [flowManager, setFlowManager] = useState<FlowManager | null>(null)
  const [isOnboardingStarted, setIsOnboardingStarted] = useState(false)

  const {
    models: { config, isNewVisitor },
    operations: { resetABTest },
  } = useFlowLoader()
  const router = useRouter()

  const { logEvent } = useAnalytics()

  const {
    models: { isReady },
  } = useLocalization()

  const {
    operations: { allowAllCookies, enableCookieBannerVisibility },
    models: { allowedCookies, areAllCookiesAllowed },
  } = useOneTrust()

  const {
    models: { user, authStatus },
  } = useAuthUser()

  const finished = useInitialNavigation({
    flowManager,
    allowAllCookies,
    resetABTest,
    authStatus,
    user,
  })
  const callbacksBeforeOpenNextPage = useRef<(() => void)[]>([])

  // create FlowManager instance
  useEffect(() => {
    if (config) {
      // WARN: override start page, DEV only
      if (process.env.APP_ENV !== 'prod') {
        const startPageId = getStartPageFromExternalSource()

        if (startPageId) {
          Object.assign(config, {
            start_page: {
              id: startPageId,
              name: startPageId,
              config: {},
            },
          })
        }
      }

      const flowManagerInstance = new FlowManager({ config })

      if (isNewVisitor) {
        FlowManager.clearCookies()
      }

      setFlowManager(flowManagerInstance)
    }
  }, [config, isNewVisitor])

  // prefetch next page for faster client-side transition
  useEffect(() => {
    if (!finished) {
      return
    }

    const isPaid = user?.is_paid
    const userID = user?.id

    const nextScreenUrl = flowManager?.getNextScreenUrl(currentScreenId, { isPaid, userID })

    if (nextScreenUrl) {
      logger.debug('FlowManagerProvider prefetch start:', nextScreenUrl)
      Router.prefetch(nextScreenUrl).then(() => {
        logger.debug('FlowManagerProvider prefetch end:', nextScreenUrl)
      })
    }
  }, [flowManager, user?.is_paid, user?.id, currentScreenId, finished])

  // send onboarding start event (one time per session)
  useEffect(() => {
    if (!isOnboardingStarted && flowManager) {
      const startOnboardingScreen = flowManager.flowMap.onboardingFlow[0]

      if (startOnboardingScreen && startOnboardingScreen.id === currentScreenId) {
        setIsOnboardingStarted(true)
        logEvent({ type: 'track', payload: { event: 'onboarding_start' } })
      }
    }
  }, [flowManager, currentScreenId, isOnboardingStarted, logEvent])

  // send event 'start_session'
  useEffect(() => {
    if (authStatus === 'done' && config) {
      logEvent({
        type: 'customData',
        payload: {
          event: `start_session`,
          data: { timestamp: window.APP_START_TIMESTAMP ?? Date.now() },
        },
      })
    }
  }, [logEvent, authStatus, config])

  useEffect(() => {
    // toggle cookie banner visibility only after navigation to the first screen
    if (finished) {
      enableCookieBannerVisibility()
    }
  }, [finished, enableCookieBannerVisibility])

  const filterOnboardingFlowByState = useCallback(
    (state: State) => {
      flowManager?.filterOnboardingFlowByState(state)
    },
    [flowManager]
  )

  const openNextPage = useOpenNextPage({
    flowManager,
    currentScreenId,
    callbacksBeforeRef: callbacksBeforeOpenNextPage,
  })
  const skipToPaymentPage = useSkipToPaymentPage({ flowManager, currentScreenId })

  // provide api to components
  const api = useMemo<FlowManagerContextInterface | undefined>(() => {
    if (!flowManager || !currentScreenId || !finished) {
      return undefined
    }

    const isPaymentFlowInProgress = flowManager.checkIsPaymentFlowInProgress(currentScreenId)
    const currentScreen = flowManager.getScreenById(currentScreenId)

    if (!currentScreen) {
      throw new Error('FlowManagerProvider: current screen not found')
    }

    const isStartPageActive = flowManager.checkIsStartPageActive(currentScreenId)

    return {
      models: {
        currentScreen,
        onboardingSteps: flowManager.onboardingSteps,
        onboardingFlow: flowManager.flowMap.onboardingFlow,
        paymentFlow: flowManager.flowMap.paymentFlow,
        isOnboardingStarted,
        // TODO: remove, replace with withPayment HOC
        isPaymentFlowInProgress,
        isStartPageActive,
      },
      operations: {
        filterOnboardingFlowByState,
        openNextPage,
        skipToPaymentPage,
        registerCallbackBeforeOpenNextPage: (callback) => {
          if (callbacksBeforeOpenNextPage.current.find((cb) => cb === callback)) return

          callbacksBeforeOpenNextPage.current.push(callback)
        },
      },
    }
  }, [
    flowManager,
    currentScreenId,
    finished,
    isOnboardingStarted,
    filterOnboardingFlowByState,
    openNextPage,
    skipToPaymentPage,
  ])

  // on payment screen allow all cookies after user confirms choice in cookie banner [omo_w-278]
  useEffect(() => {
    if (api?.models.isPaymentFlowInProgress && allowedCookies && !areAllCookiesAllowed) {
      allowAllCookies()
    }
  }, [allowAllCookies, api, allowedCookies, areAllCookiesAllowed])

  useEffect(() => {
    router.beforePopState(() => {
      flowManager?.restoreOnboardingFlow()

      const canGoBack =
        !flowManager?.checkIsPaymentFlowInProgress(currentScreenId) &&
        currentScreenId !== 'ob_payment_success_register'

      return canGoBack
    })
  }, [router, flowManager, currentScreenId])

  const isStartScreen = useMemo(() => startScreensIds.includes(currentScreenId), [currentScreenId])

  // allow start screens to be rendered even if api isn't ready
  // so that they are prerendered at build time
  // https://gitlab.asqq.io/forerunner/forerunner-front/-/blob/stage/docs/development.md#start-screens
  if (!api && !isStartScreen) {
    return null
  }

  return (
    <FlowManagerContext.Provider value={api || null}>
      {isReady && (finished || isStartScreen) && children}
    </FlowManagerContext.Provider>
  )
}
