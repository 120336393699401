import { useMemo } from 'react'

import { EatingProfile, EatingProfileByNHELevels } from 'shared/constants/eatingProfile'
import { useClientData } from 'shared/providers/ClientDataProvider'
import { nonNullable } from 'shared/utils/common'
import { calculateScore, getLevel } from 'shared/utils/debq'

const restrainedEatingRange = {
  min: 10,
  max: 50,
}
const emotionalEatingRange = {
  min: 13,
  max: 65,
}
const externalEatingRange = {
  min: 10,
  max: 50,
}

export const useDEBQQuizResults = () => {
  const { emotionalEatingValues, externalEatingValues, restrainedEatingValues } =
    useGroupedEatingLevelScores()

  return useMemo(() => {
    const flowHadQuiz =
      emotionalEatingValues.every(nonNullable) &&
      externalEatingValues.every(nonNullable) &&
      restrainedEatingValues.every(nonNullable)

    if (!flowHadQuiz) {
      const defaultLevel = {
        binary: 0,
        percent: 0,
      }

      // If there was no quiz in onboarding, then assign a default profile
      return {
        eatingProfile: EatingProfile.Pacifier,
        emotionalLevel: defaultLevel,
        externalLevel: defaultLevel,
        restrainedLevel: defaultLevel,
      }
    }

    const emotionalScore = calculateScore(emotionalEatingValues)
    const externalScore = calculateScore(externalEatingValues)
    const restrainedScore = calculateScore(restrainedEatingValues)

    const emotionalLevel = getLevel(emotionalScore, emotionalEatingRange)
    const externalLevel = getLevel(externalScore, externalEatingRange)
    const restrainedLevel = getLevel(restrainedScore, restrainedEatingRange)

    const key = `${restrainedLevel.binary}_${emotionalLevel.binary}_${externalLevel.binary}`
    const eatingProfile = EatingProfileByNHELevels[key] || EatingProfile.Pacifier

    return { eatingProfile, emotionalLevel, externalLevel, restrainedLevel }
  }, [emotionalEatingValues, externalEatingValues, restrainedEatingValues])
}

const useGroupedEatingLevelScores = () => {
  const {
    state: {
      debqExternalSnackingWhileCooking,
      debqExternalEatMoreWhenOthersEating,
      debqExternalHardToResistTastyFood,
      debqExternalEatWhenSeeEatingPeople,
      debqExternalBuyFoodWhenPassingCafe,
      debqExternalBuyFoodWhenPassingBakery,
      debqExternalEatDeliciousFoodInstantly,
      debqExternalEatIfGoodLookingFood,
      debqExternalEatMoreWhenGoodLookingFood,
      debqExternalEatTastyFood,
      debqEmotionalEatWhenBored,
      debqEmotionalEatWhenEmotionalUpset,
      debqEmotionalEatWhenDisappointed,
      debqEmotionalEatWhenScared,
      debqEmotionalEatWhenFailed,
      debqEmotionalEatWhenWorried,
      debqEmotionalEatDuringUnpleasantAnticipation,
      debqEmotionalEatWhenUpset,
      debqEmotionalEatWhenLetDown,
      debqEmotionalEatWhenLonely,
      debqEmotionalEatWhenDepressed,
      debqEmotionalEatWhenChilling,
      debqEmotionalEatWhenIrritated,
      debqRestrainedRefuseFood,
      debqRestrainedConsiderWeightWhenChooseFood,
      debqRestrainedNoEveningEating,
      debqRestrainedNoEatBetweenMeals,
      debqRestrainedEatLess,
      debqRestrainedEatLessAfterOvereating,
      debqRestrainedFoodForWl,
      debqRestrainedEatingMonitoring,
      debqRestrainedEatLessDuringMeals,
      debqRestrainedEatLessWhenGainWeight,
    },
  } = useClientData()

  return useMemo(() => {
    const restrainedEatingValues = [
      debqRestrainedRefuseFood,
      debqRestrainedConsiderWeightWhenChooseFood,
      debqRestrainedNoEveningEating,
      debqRestrainedNoEatBetweenMeals,
      debqRestrainedEatLess,
      debqRestrainedEatLessAfterOvereating,
      debqRestrainedFoodForWl,
      debqRestrainedEatingMonitoring,
      debqRestrainedEatLessDuringMeals,
      debqRestrainedEatLessWhenGainWeight,
    ]

    const emotionalEatingValues = [
      debqEmotionalEatWhenBored,
      debqEmotionalEatWhenEmotionalUpset,
      debqEmotionalEatWhenDisappointed,
      debqEmotionalEatWhenScared,
      debqEmotionalEatWhenFailed,
      debqEmotionalEatWhenWorried,
      debqEmotionalEatDuringUnpleasantAnticipation,
      debqEmotionalEatWhenUpset,
      debqEmotionalEatWhenLetDown,
      debqEmotionalEatWhenLonely,
      debqEmotionalEatWhenDepressed,
      debqEmotionalEatWhenChilling,
      debqEmotionalEatWhenIrritated,
    ]

    const externalEatingValues = [
      debqExternalSnackingWhileCooking,
      debqExternalEatMoreWhenOthersEating,
      debqExternalHardToResistTastyFood,
      debqExternalEatWhenSeeEatingPeople,
      debqExternalBuyFoodWhenPassingCafe,
      debqExternalBuyFoodWhenPassingBakery,
      debqExternalEatDeliciousFoodInstantly,
      debqExternalEatIfGoodLookingFood,
      debqExternalEatMoreWhenGoodLookingFood,
      debqExternalEatTastyFood,
    ]

    return {
      restrainedEatingValues,
      emotionalEatingValues,
      externalEatingValues,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export type QuizResultsReturnType = ReturnType<typeof useDEBQQuizResults>
