import { MessageDescriptor } from 'react-intl'

import { IconProfileEmpath } from 'ui-elements/Icons/IconProfileEmpath'
import { IconProfileEmpathWhite } from 'ui-elements/Icons/IconProfileEmpathWhite'
import { IconProfileEnthusiast } from 'ui-elements/Icons/IconProfileEnthusiast'
import { IconProfileEnthusiastWhite } from 'ui-elements/Icons/IconProfileEnthusiastWhite'
import { IconProfileExplorer } from 'ui-elements/Icons/IconProfileExplorer'
import { IconProfileExplorerWhite } from 'ui-elements/Icons/IconProfileExplorerWhite'
import { IconProfileGuardian } from 'ui-elements/Icons/IconProfileGuardian'
import { IconProfileGuardianWhite } from 'ui-elements/Icons/IconProfileGuardianWhite'
import { IconProfileHarmoniser } from 'ui-elements/Icons/IconProfileHarmoniser'
import { IconProfileHarmoniserWhite } from 'ui-elements/Icons/IconProfileHarmoniserWhite'
import { IconProfilePacifier } from 'ui-elements/Icons/IconProfilePacifier'
import { IconProfilePacifierWhite } from 'ui-elements/Icons/IconProfilePacifierWhite'
import { IconProfileRegulator } from 'ui-elements/Icons/IconProfileRegulator'
import { IconProfileRegulatorWhite } from 'ui-elements/Icons/IconProfileRegulatorWhite'
import { IconProfileStoic } from 'ui-elements/Icons/IconProfileStoic'
import { IconProfileStoicWhite } from 'ui-elements/Icons/IconProfileStoicWhite'

import { messages } from 'shared/intl/eatingProfiles.intl'

// https://www.notion.so/forerunner-team/19b483fe204648b790e126a5a666bed1?v=0eb7e38ae933413baa6da6d7280cc309

export enum EatingProfile {
  Regulator = 'regulator',
  Guardian = 'guardian',
  Explorer = 'explorer',
  Stoic = 'stoic',
  Pacifier = 'pacifier',
  Empath = 'empath',
  Enthusiast = 'enthusiast',
  Harmonizer = 'harmonizer',
}

export const EatingProfileIcons: Record<EatingProfile, typeof IconProfileRegulator> = {
  [EatingProfile.Regulator]: IconProfileRegulator,
  [EatingProfile.Guardian]: IconProfileGuardian,
  [EatingProfile.Explorer]: IconProfileExplorer,
  [EatingProfile.Stoic]: IconProfileStoic,
  [EatingProfile.Pacifier]: IconProfilePacifier,
  [EatingProfile.Empath]: IconProfileEmpath,
  [EatingProfile.Enthusiast]: IconProfileEnthusiast,
  [EatingProfile.Harmonizer]: IconProfileHarmoniser,
}

export const EatingProfileIconsWithShadow: Record<EatingProfile, typeof IconProfileRegulator> = {
  [EatingProfile.Regulator]: IconProfileRegulatorWhite,
  [EatingProfile.Guardian]: IconProfileGuardianWhite,
  [EatingProfile.Explorer]: IconProfileExplorerWhite,
  [EatingProfile.Stoic]: IconProfileStoicWhite,
  [EatingProfile.Pacifier]: IconProfilePacifierWhite,
  [EatingProfile.Empath]: IconProfileEmpathWhite,
  [EatingProfile.Enthusiast]: IconProfileEnthusiastWhite,
  [EatingProfile.Harmonizer]: IconProfileHarmoniserWhite,
}

export const EatingProfileTitles: Record<EatingProfile, MessageDescriptor> = {
  [EatingProfile.Regulator]: messages['common.eatingProfile.regulator'],
  [EatingProfile.Guardian]: messages['common.eatingProfile.guardian'],
  [EatingProfile.Explorer]: messages['common.eatingProfile.explorer'],
  [EatingProfile.Stoic]: messages['common.eatingProfile.stoic'],
  [EatingProfile.Pacifier]: messages['common.eatingProfile.pacifier'],
  [EatingProfile.Empath]: messages['common.eatingProfile.empath'],
  [EatingProfile.Enthusiast]: messages['common.eatingProfile.enthusiast'],
  [EatingProfile.Harmonizer]: messages['common.eatingProfile.harmonizer'],
}

export const EatingProfileDescriptions: Record<EatingProfile, MessageDescriptor> = {
  [EatingProfile.Regulator]: messages['common.eatingProfile.regulator.description'],
  [EatingProfile.Guardian]: messages['common.eatingProfile.guardian.description'],
  [EatingProfile.Explorer]: messages['common.eatingProfile.explorer.description'],
  [EatingProfile.Stoic]: messages['common.eatingProfile.stoic.description'],
  [EatingProfile.Pacifier]: messages['common.eatingProfile.pacifier.description'],
  [EatingProfile.Empath]: messages['common.eatingProfile.empath.description'],
  [EatingProfile.Enthusiast]: messages['common.eatingProfile.enthusiast.description'],
  [EatingProfile.Harmonizer]: messages['common.eatingProfile.harmonizer.description'],
}

// {restrained binary level}_{emotional binary level}_{external binary level}
export const EatingProfileByNHELevels: Record<string, EatingProfile> = {
  '0_0_0': EatingProfile.Harmonizer,
  '0_0_1': EatingProfile.Enthusiast,
  '0_1_0': EatingProfile.Empath,
  '0_1_1': EatingProfile.Pacifier,
  '1_0_0': EatingProfile.Stoic,
  '1_0_1': EatingProfile.Explorer,
  '1_1_0': EatingProfile.Guardian,
  '1_1_1': EatingProfile.Regulator,
}
