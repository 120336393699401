// Formula: https://www.notion.so/forerunner-team/Questionnaire-and-scoring-36cf68a3b24c4341a61a95f5c42a02c0

export const calculateScore = (values: Array<string | undefined>) => {
  return values.reduce((sum, value) => sum + Number(value) || 0, 0)
}

type PossibleScore = {
  min: number
  max: number
}

export const getLevel = (
  actualScore: number,
  { min, max }: PossibleScore
): { binary: 1 | 0; percent: number } => {
  const percentLevel = ((actualScore - min) / (max - min)) * 100

  return {
    binary: percentLevel > 50 ? 1 : 0,
    percent: Math.round(percentLevel),
  }
}
